import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TableCell, TextField, Toolbar } from "@mui/material";
import React from "react";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import CustomTable from "../../../controls/table/CustomTable";
import { HeadCell, BaseData } from "../../../controls/table/CustomTable";
import { UserListModel } from "../../../../models/UserModel";
import { SortOrderModel } from "../../../../models/SortOrderModels"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { green, red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import ConfirmDialog from "../../../dialogs/confirm_dialog/ConfirmDialog";
import { RoleModel } from "../../../../models/RoleModel";
import { ApplicationUser } from "../../../../models/AuthModels";
import { appConfig } from "../../../../AppConfig";
import InsufficientAccess from "../../InsufficientAccess";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		width: 250,
	  },
	},
  };

interface UsersListProps extends WrappedComponentProps, WithRouterProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser
}

interface UsersListState {
	page: number;
	records: number;
	recordPerPage: number;
	data: Array<BaseData>;
	cells: Array<HeadCell>;
	search?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	username?: string;
	email?: string;
	phoneNumber?: string;
	fieldsOrder?: Array<SortOrderModel>;
	allRoles?: Array<RoleModel>;
	roles?: Array<string>;
	isActive?: boolean;
}

class UsersList extends React.Component<UsersListProps, UsersListState> {
	private roles: Array<String>;
	private hasAccess: boolean;

	constructor(props: UsersListProps) {
		super(props);

		this.roles = this.props.currentUser?.roles ?? [];
		this.hasAccess = (this.props.isUserLoggedIn && (this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN))) ?? false;

		this.state = {
			page: 0,
			records: 0,
			recordPerPage: 10,
			roles: [],
			data: [],
			cells: [
				{disablePadding: false, id: "fullname", label: "Name", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "username", label: "Username", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "email", label: "Email", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "phonenumber", label: "Phone Number", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "roles", label: "Roles", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "isactive", label: "Is Active", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "actions", label: "Actions", numeric: false, orderEnabled: false}],
		}

		if(this.hasAccess) {
			this.getRoles();
			this.getData();
		}
	}

	showConfirmDialog(id: string) {
		ConfirmDialog.Show(
			"danger",
			"Delete User",
			"Are you shure that you want to delete this user? The operation can not be reverted!",
			[
				{
					text: "Delete",
					props: {
						variant: "contained",
						color: "error",
						onClick: (event) => this.handleDelete(event, id)
					}
				}
			]
		);
	}

	 onFieldChangeAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		switch(name) {
			case "search": {
				this.setState({
					search: value
				});
				break;
			}
			case "first-name": {
				this.setState({
					firstName: value
				});
				break;
			}
			case "middle-name": {
				this.setState({
					middleName: value
				});
				break;
			}
			case "last-name": {
				this.setState({
					lastName: value
				});
				break;
			}
			case "username": {
				this.setState({
					username: value
				});
				break;
			}
			case "email": {
				this.setState({
					email: value
				});
				break;
			}
			case "phonenumber": {
				this.setState({
					phoneNumber: value
				});
				break;
			}
			default:
				break;
		}
	};

	 handleRequestSort = async (event: React.MouseEvent<unknown>, property: string,
	 ) => {
		let fieldsOrder = this.state.fieldsOrder;
		let cells = this.state.cells;

		cells.map((item, index) => {
			if(item.id === property){
				item.order = (item.order ?  (item.order=== "asc" ? "desc" : undefined) : "asc");
			}
		});

		if(fieldsOrder && fieldsOrder.length > 0){
			let field = fieldsOrder.find((item) => {
				return item.name === property;
			});

			if(field){
				if(field.order === "desc"){
					fieldsOrder = fieldsOrder.filter((item) => {
						return item.name !== property && item.order !== "desc";
					});
				}
				else{
					fieldsOrder.map((item, index) => {
						if(item.name === property){
							item.order = (item.order === "asc" ? "desc" : "asc")
						}
					});
				}
			}
			else {
				fieldsOrder.push({name: property, order: "asc"});
			}
		}
		else {
			fieldsOrder = [{name: property, order: "asc"}];
		}

		await this.setState({
			fieldsOrder: fieldsOrder,
			cells: cells
		});

		this.getData();
	 };
   
	 handleChangePage = async (event: unknown, newPage: number) => {
	   await this.setState({
			page: newPage
		});

		this.getData();
	 };

	 handleSearch = async (event: unknown) => {
		await this.setState({
			page: 0
		});
		this.getData();
	  };

	  handleAdd = (event: unknown) => {
		this.props.navigate("/admin/users/create");
	  };

	  handleEdit = (event: unknown, id: string) => {
		this.props.navigate(`/admin/users/${id}`, {state: {id: id}});
	  };

	  handleDelete = (event: unknown, id: string) => {
		http.delete(`/users/${id}`).then((response) => {
			this.getData();
		}).catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message);
		});;
	  };
   
	 handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let recordsPerPage = parseInt(event.target.value, 10);

		await this.setState({
			recordPerPage: recordsPerPage
		});

		this.getData();
	 };

	 handleMultiSelectChange = (event: SelectChangeEvent<typeof this.state.roles>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			roles: typeof value === 'string' ? value.split(',') : value
		});
	  };

	  handleSelectChange = (event: SelectChangeEvent<typeof this.state.isActive>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			isActive: typeof value === 'string' ? undefined : value
		});
	  };

	 getRoles() {
		http.get(`/roles`)
		.then((response) => {
			let responseData = response.data as Array<RoleModel>;
			this.setState({
				allRoles: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	 }

	 getData(){
		const params = {
			page: this.state.page <= 0 ? 1 : this.state.page+1,
			records: this.state.recordPerPage,
			fieldsOrder: this.state.fieldsOrder,
			search: this.state.search,
			firstName: this.state.firstName,
			middleName: this.state.middleName,
			lastName: this.state.lastName,
			email: this.state.email,
			username: this.state.username,
			phoneNumber: this.state.phoneNumber,
			isActive: this.state.isActive,
			roles: this.state.roles
		  };

		http.get(`/users`, 
			{params})
			.then((response) => {
				let responseData = response.data.data as Array<UserListModel>;

				this.setState({
					page: response.data.pages < response.data.page ? 0 : response.data.page-1,
					records: response.data.records,
					data: responseData?.map((row, index) => {
						return(
							({id: row.id, cells: [
								<TableCell key={'name-'+row.id} component="th" scope="row">{row.fullName}</TableCell>,
								<TableCell key={'username-'+row.id}>{row.username}</TableCell>,
								<TableCell key={'email-'+row.id}>
									<Stack alignItems="center" direction="row" gap={1}>
										{row.email} 
										{row.isEmailVerified ? 
										<CheckCircleOutlineIcon sx={{color: green[500]}} /> : 
										<HighlightOffIcon sx={{color: red[500]}} />}
									</Stack>
								</TableCell>,
								<TableCell key={'phone-number-'+row.id}>{row.phoneNumber ?
									<Stack alignItems="center" direction="row" gap={1}>
										{row.phoneNumber}
										{row.isPhoneNumberVerified ? 
										<CheckCircleOutlineIcon sx={{color: green[500]}} /> : 
										<HighlightOffIcon sx={{color: red[500]}} />}
									</Stack> :
									<></>}
								</TableCell>,
								<TableCell key={'roles-'+row.id}>{row.roles?.map(role => role.name).join(', ')}</TableCell>,
								<TableCell key={'is-active-'+row.id}>{row.isActive ? "Yes" : "No"}</TableCell>,
								<TableCell key={'actions-'+row.id}>
									<Stack alignItems="center" direction="row" gap={1}>
										<Button 
											size="small" 
											variant="outlined"
											onClick={event => this.handleEdit(event, row.id)}
											>
											<Stack alignItems="center" direction="row" gap={1}>
												<EditIcon />
											</Stack>
										</Button>
										{(this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN)) && <Button 
										sx={{color: red[500]}} 
										size="small" 
										variant="outlined" 
										onClick={event => this.showConfirmDialog(row.id)}>
											<Stack alignItems="center" direction="row" gap={1}>
												<DeleteIcon />
											</Stack>
										</Button>}
									</Stack>
								</TableCell>
							]})
						);
					})
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	 }

	getTableFilters = () => {
		return [
			<Toolbar key="filter-group-1"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="first-name"
						label="First Name"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="middle-name"
						label="Middle Name" 
						onChange={this.onFieldChangeAsync.bind(this)}/>
				</FormControl>
				<FormControl 
					sx={{
						//pr: { xs: 3, sm: 3 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="last-name"
						label="Last Name" 
						onChange={this.onFieldChangeAsync.bind(this)}/>
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-2"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="username"
						label="Username"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="email"
						label="Email"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl 
					sx={{
						//pr: { xs: 3, sm: 3 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="phonenumber"
						label="Phone Number"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-3"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						//minWidth: "540px"
						minWidth: 1/3
						//size: "large"
					}}>
					<InputLabel id="select-multiple-roles-label">Roles</InputLabel>
					<Select
						sx={{
							width: "100%"
						}}
						multiple
						labelId="select-multiple-roles-label"
						id="select-roles-id"
						value={this.state.roles}
						input={<OutlinedInput id="select-multiple-roles" label="Roles" />}
						onChange={this.handleMultiSelectChange}
						renderValue={(selected) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							  {selected.map((value) => (
								<Chip key={value} label={this.state.allRoles?.find((item) => {
									return item.id === value;
								})?.name} />
							  ))}
							</Box>
						  )}
						MenuProps={MenuProps}>
						{this.state?.allRoles?.map((item) => {
							return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
						})}
					</Select>
				</FormControl>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
						//minWidth: "540px"
					}}>
					<InputLabel id="select-is-active-label">Is Active?</InputLabel>
					<Select
						// sx={{
						// 	width: "100%"
						// }}
						labelId="select-is-active-label"
						input={<OutlinedInput id="select-is-active" label="Is Active" />}
						value={this.state.isActive ?? ''}
						label="Is Active?"
						onChange={this.handleSelectChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={true as any}>Yes</MenuItem>
						<MenuItem value={false as any}>No</MenuItem>
					</Select>
				</FormControl>
			</Toolbar>
			];
	}

	render(): React.ReactNode {
		if(this.hasAccess)
			return(
				<CustomTable
					data={this.state.data}
					cells={this.state.cells}
					recordsPerPage={[10, 25, 50]}
					recordPerPage={this.state.recordPerPage}
					records={this.state.records}
					page={this.state.page}
					onFieldChangeAsync={this.onFieldChangeAsync}
					filters={this.getTableFilters()}
					handleChangePage={this.handleChangePage}
					handleChangeRowsPerPage={this.handleChangeRowsPerPage}
					handleRequestSort={this.handleRequestSort}
					handleSearch={this.handleSearch}
					handleAdd={this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN) ? this.handleAdd : undefined}
				/>
			);
		return(<InsufficientAccess />)
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, null)(UsersList)));
//export default UsersList;
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TableCell, TextField, Toolbar } from "@mui/material";
import React from "react";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import CustomTable from "../../../controls/table/CustomTable";
import { HeadCell, BaseData } from "../../../controls/table/CustomTable";
import { SortOrderModel } from "../../../../models/SortOrderModels"
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import ConfirmDialog from "../../../dialogs/confirm_dialog/ConfirmDialog";
import { CountryModel } from "../../../../models/CountryModels";
import { CampaignListModel, Status } from "../../../../models/CampaignModels";
import { EmailTemplateSimpleModel } from "../../../../models/EmailTemplateModels";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimeValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ApplicationUser } from "../../../../models/AuthModels";
import { appConfig } from "../../../../AppConfig";
import { TagModel } from "../../../../models/TagModels";
import { EmailSenderSimpleModel } from "../../../../models/EmailSenderModels";

interface CampaignsListProps extends WrappedComponentProps, WithRouterProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser
}

interface CampaignsListState {
	page: number;
	records: number;
	recordPerPage: number;
	data: Array<BaseData>;
	cells: Array<HeadCell>;
    fieldsOrder?: Array<SortOrderModel>;
	search?: string;
    name?: string;
    description?: string;
    emailTemplate?: string;
    country?: string;
    status?: string;
    startAt: Dayjs | null;
    endAt: Dayjs | null;
	emailOpenFrom?: number;
	emailOpenTo?: number;
    allCountries?: Array<CountryModel>;
	allEmailTemplates?: Array<EmailTemplateSimpleModel>;
	tagId?: string;
	tags?: Array<TagModel>;
	emailSenderId?: string;
	emailSenders?: Array<EmailSenderSimpleModel>;
}

class CampaignsList extends React.Component<CampaignsListProps, CampaignsListState> {
	private roles: Array<String>;
	private hasAccess: boolean;

	constructor(props: CampaignsListProps) {
		super(props);

		this.roles = this.props.currentUser?.roles ?? [];
		this.hasAccess = (this.props.isUserLoggedIn && (this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN))) ?? false;
		
		this.state = {
			page: 0,
			records: 0,
			recordPerPage: 10,
			data: [],
			cells: [
				{disablePadding: false, id: "name", label: "Name", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "description", label: "Description", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "emailtemplate", label: "Email Template", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "tag", label: "Tag", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "emailsender", label: "Email Sender", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "country", label: "Country", numeric: false, orderEnabled: true},
                {disablePadding: false, id: "status", label: "Status", numeric: false, orderEnabled: true},
                {disablePadding: false, id: "startat", label: "Start At", numeric: false, orderEnabled: true},
                {disablePadding: false, id: "endat", label: "End At", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "emailopenfrom", label: "Email Open Count From", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "emailopento", label: "Email Open Count To", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "actions", label: "Actions", numeric: false, orderEnabled: false}],
			startAt: null,
			endAt: null
		}

		this.getCountries();
		this.getEmailTemplates();
		this.getTags();
		this.getData();
		this.getEmailSenders();
	}

	showConfirmDialog(id: string) {
		if(this.hasAccess){
			ConfirmDialog.Show(
				"danger",
				"Delete Campaign",
				"Are you shure that you want to delete this campaign? The operation can not be reverted!",
				[
					{
						text: "Delete",
						props: {
							variant: "contained",
							color: "error",
							onClick: (event) => this.handleDelete(event, id)
						}
					}
				]
			);
		}
	}

	 onFieldChangeAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		switch(name) {
			case "search": {
				this.setState({
					search: value
				});
				break;
			}
			case "name": {
				this.setState({
					name: value
				});
				break;
			}
			case "description": {
				this.setState({
					description: value
				});
				break;
			}
			case "emailopenfrom":{
				const isNegativeCharacter = value.startsWith("-") && value.length === 1;

				let numberValue = parseInt(isNegativeCharacter ? value+"0" : (value === "" ? "0" : value) , 10);
				if(Number.isNaN(numberValue) && value.length >= 1)
					numberValue = 0;

				this.setState({
					emailOpenFrom: value.length === 0 ? undefined : numberValue
				});
				break;
			}
			case "emailopento": {
				const isNegativeCharacter = value.startsWith("-") && value.length === 1;

				let numberValue = parseInt(isNegativeCharacter ? value+"0" : (value === "" ? "0" : value) , 10);
				if(Number.isNaN(numberValue) && value.length >= 1)
					numberValue = 0;

				this.setState({
					emailOpenTo: value.length === 0 ? undefined : numberValue
				});
				break;
			}
			default:
				break;
		}
	};

	onStartAtChangeAsync = async (value: dayjs.Dayjs | null, event: PickerChangeHandlerContext<DateTimeValidationError>) => {
		await this.setState({
			startAt: value
		});
	};

	onEndAtChangeAsync = async (value: dayjs.Dayjs | null, event: PickerChangeHandlerContext<DateTimeValidationError>) => {
		await this.setState({
			endAt: value
		});
	};

	 handleRequestSort = async (event: React.MouseEvent<unknown>, property: string,
	 ) => {
		let fieldsOrder = this.state.fieldsOrder;
		let cells = this.state.cells;

		cells.map((item, index) => {
			if(item.id === property){
				item.order = (item.order ?  (item.order=== "asc" ? "desc" : undefined) : "asc");
			}
		});

		if(fieldsOrder && fieldsOrder.length > 0){
			let field = fieldsOrder.find((item) => {
				return item.name === property;
			});

			if(field){
				if(field.order === "desc"){
					fieldsOrder = fieldsOrder.filter((item) => {
						return item.name !== property && item.order !== "desc";
					});
				}
				else{
					fieldsOrder.map((item, index) => {
						if(item.name === property){
							item.order = (item.order === "asc" ? "desc" : "asc")
						}
					});
				}
			}
			else {
				fieldsOrder.push({name: property, order: "asc"});
			}
		}
		else {
			fieldsOrder = [{name: property, order: "asc"}];
		}

		await this.setState({
			fieldsOrder: fieldsOrder,
			cells: cells
		});

		this.getData();
	 };
   
	 handleChangePage = async (event: unknown, newPage: number) => {
	   await this.setState({
			page: newPage
		});

		this.getData();
	 };

	 handleSearch = async (event: unknown) => {
		await this.setState({
			page: 0
		});
		this.getData();
	  };

	  handleAdd = (event: unknown) => {
		this.props.navigate("/admin/campaigns/create");
	  };

	  handleEdit = (event: unknown, id: string) => {
		this.props.navigate(`/admin/campaigns/${id}`, {state: {id: id}});
	  };

	  handleDelete = (event: unknown, id: string) => {
		if(this.hasAccess){
			http.delete(`/campaigns/${id}`).then((response) => {
				this.getData();
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
		}
	};
   
	 handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let recordsPerPage = parseInt(event.target.value, 10);

		await this.setState({
			recordPerPage: recordsPerPage
		});

		this.getData();
	};

	  handleSelectCountryChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			country: value
		});
	};

	  handleSelectStatusChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			status: value
		});
	};

	handleSelectTagChange = (event: SelectChangeEvent<typeof this.state.tagId>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			tagId: value
		});
	};

	handleSelectEmailSenderChange = (event: SelectChangeEvent<typeof this.state.emailSenderId>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			emailSenderId: value
		});
	};

	  handleSelectEmailTemplateChange = (event: SelectChangeEvent<typeof this.state.emailTemplate>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			emailTemplate: value
		});
	};

	 getCountries() {
		http.get(`/countries`)
		.then((response) => {
			let responseData = response.data as Array<CountryModel>;
			this.setState({
				allCountries: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getTags() {
		http.get(`/tags/getAll`)
		.then((response) => {
			let responseData = response.data as Array<TagModel>;
			this.setState({
				tags: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getEmailSenders() {
		http.get(`/emailSenders/getAll`)
		.then((response) => {
			let responseData = response.data as Array<EmailSenderSimpleModel>;
			this.setState({
				emailSenders: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	 getEmailTemplates() {
		http.get(`/emailTemplates/getAll`)
		.then((response) => {
			let responseData = response.data as Array<EmailTemplateSimpleModel>;
			this.setState({
				allEmailTemplates: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getData(){
		const params = {
			page: this.state.page <= 0 ? 1 : this.state.page+1,
			records: this.state.recordPerPage,
			fieldsOrder: this.state.fieldsOrder,
			search: this.state.search,
            name: this.state.name,
            description: this.state.description,
            emailTemplateId: this.state.emailTemplate,
            countryId: this.state.country,
            status: this.state.status,
            startAt: this.state.startAt !== null ? this.state.startAt.format('DD-MM-YYYY HH:mm') : undefined,
            endAt: this.state.endAt !== null ? this.state.endAt.format('DD-MM-YYYY HH:mm') : undefined,
			emailOpenFrom: this.state.emailOpenFrom,
			emailOpenTo: this.state.emailOpenTo,
			tagId: this.state.tagId,
			emailSenderId: this.state.emailSenderId
		  };

		http.get(`/campaigns`, 
			{params})
			.then((response) => {
				let responseData = response.data.data as Array<CampaignListModel>;

				this.setState({
					page: response.data.pages < response.data.page ? 0 : response.data.page-1,
					records: response.data.records,
					data: responseData?.map((row, index) => {
						return(
							({id: row.id, cells: [
								<TableCell key={'name-'+row.id} component="th" scope="row">{row.name}</TableCell>,
								<TableCell key={'description-'+row.id}>{row.description}</TableCell>,
                                <TableCell key={'email-template-'+row.id}>{row.emailTemplate}</TableCell>,
								<TableCell key={'tag-'+row.id}>{row.tag}</TableCell>,
								<TableCell key={'email-sender-' + row.id}>{row.emailSender}</TableCell>,
                                <TableCell key={'country-'+row.id}>{row.country}</TableCell>,
								<TableCell key={'status-'+row.id}>{Status[row.status]}</TableCell>,
								<TableCell key={'start-at-'+row.id}>{row.startAt}</TableCell>,
                                <TableCell key={'end-at-'+row.id}>{row.endAt}</TableCell>,
								<TableCell key={'email-open-from-'+row.id}>{row.emailOpenFrom}</TableCell>,
								<TableCell key={'email-open-to-'+row.id}>{row.emailOpenTo}</TableCell>,
								<TableCell key={'actions-'+row.id}>
									<Stack alignItems="center" direction="row" gap={1}>
										<Button 
											size="small" 
											variant="outlined"
											onClick={event => this.handleEdit(event, row.id)}
											>
											<Stack alignItems="center" direction="row" gap={1}>
												<EditIcon />
											</Stack>
										</Button>
										<Button 
											disabled={!this.hasAccess}
											sx={{color: red[500]}} 
											size="small" 
											variant="outlined" 
											onClick={event => this.showConfirmDialog(row.id)}>
											<Stack alignItems="center" direction="row" gap={1}>
												<DeleteIcon />
											</Stack>
										</Button>
									</Stack>
								</TableCell>
							]})
						);
					})
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	}

	getTableFilters = () => {
		return [
			<Toolbar key="filter-group-1"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="name"
						label="Name"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="description"
						label="Description"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl
					sx={{
						//pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-email-template-label">Email Template</InputLabel>
					<Select
						labelId="select-email-template-label"
						input={<OutlinedInput id="select-email-template" label="Email Template" />}
						value={this.state.emailTemplate ?? ''}
						label="Email Template"
						onChange={this.handleSelectEmailTemplateChange}>
						<MenuItem><em>All</em></MenuItem>
						{this.state?.allEmailTemplates?.map((item) => {
                            return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                        })}
					</Select>
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-2"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-country-label">Country</InputLabel>
					<Select
						labelId="select-country-label"
						input={<OutlinedInput id="select-country" label="Country" />}
						value={this.state.country ?? ''}
						label="Country"
						onChange={this.handleSelectCountryChange}>
						<MenuItem><em>All</em></MenuItem>
						{this.state?.allCountries?.map((item) => {
                            return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                        })}
					</Select>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<FormControl
						sx={{
							pr: { xs: 2, sm: 2 },
							minWidth: 1/3
						}}>
						<DateTimePicker
							label="Start At"
							value={this.state.startAt}
							maxDateTime={this.state.endAt !== null ? this.state.endAt : undefined}
							onChange={this.onStartAtChangeAsync.bind(this)}
							format="DD-MM-YYYY HH:mm"
							/>
					</FormControl>
					<FormControl
						sx={{
							//pr: { xs: 2, sm: 2 },
							minWidth: 1/3
						}}>
						<DateTimePicker
							label="End At"
							value={this.state.endAt}
							minDateTime={this.state.startAt !== null ? this.state.startAt : undefined}
							onChange={this.onEndAtChangeAsync.bind(this)}
							format="DD-MM-YYYY HH:mm"
							/>
					</FormControl>
				</LocalizationProvider>
			</Toolbar>,
			<Toolbar key="filter-group-3"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-status-label">Status</InputLabel>
					<Select
						labelId="select-status-label"
						input={<OutlinedInput id="select-status" label="Status" />}
						value={this.state.status ?? ''}
						label="Status"
						onChange={this.handleSelectStatusChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={0}>None</MenuItem>
						<MenuItem value={1}>Pending</MenuItem>
						<MenuItem value={2}>Processing</MenuItem>
						<MenuItem value={3}>Finished</MenuItem>
					</Select>
				</FormControl>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="emailopenfrom"
						label="Email Open Count From"
						value={this.state.emailOpenFrom}
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
				<FormControl 
					sx={{
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="emailopento"
						label="Email Open Count To"
						value={this.state.emailOpenTo}
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-4"
			sx={{
				pl: { xs: 2 },
				pr: { xs: 2 },
				pb: { xs: 2 }
			}}>
			<FormControl
				sx={{
					pr: { xs: 2, sm: 2 },
					minWidth: 1/3
				}}>
				<InputLabel id="select-tag-label">Tag</InputLabel>
				<Select
					labelId="select-tag-label"
					input={<OutlinedInput id="select-tag" label="Tag" />}
					value={this.state.tagId ?? ''}
					label="Tag"
					onChange={this.handleSelectTagChange}>
					<MenuItem><em>All</em></MenuItem>
					{this.state?.tags?.map((item) => {
						return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
					})}
				</Select>
			</FormControl>
			<FormControl
				sx={{
					pr: { xs: 2, sm: 2 },
					minWidth: 1/3
				}}>
				<InputLabel id="select-email-sender-label">Email Sender</InputLabel>
				<Select
					labelId="select-email-sender-label"
					input={<OutlinedInput id="select-email-sender" label="Email Sender" />}
					value={this.state.emailSenderId ?? ''}
					label="Tag"
					onChange={this.handleSelectEmailSenderChange}>
					<MenuItem><em>All</em></MenuItem>
					{this.state?.emailSenders?.map((item) => {
						return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
					})}
				</Select>
			</FormControl>
		</Toolbar>
			];
	}

	render(): React.ReactNode {
		return(
			<>
				<CustomTable
					data={this.state.data}
					cells={this.state.cells}
					recordsPerPage={[10, 25, 50]}
					recordPerPage={this.state.recordPerPage}
					records={this.state.records}
					page={this.state.page}
					onFieldChangeAsync={this.onFieldChangeAsync}
					filters={this.getTableFilters()}
					handleChangePage={this.handleChangePage}
					handleChangeRowsPerPage={this.handleChangeRowsPerPage}
					handleRequestSort={this.handleRequestSort}
					handleSearch={this.handleSearch}
					handleAdd={this.handleAdd}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};
export default withRouter(injectIntl(connect(mapStateToProps, null)(CampaignsList)));
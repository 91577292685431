import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TableCell, TextField, Toolbar } from "@mui/material";
import React from "react";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import CustomTable from "../../../controls/table/CustomTable";
import { HeadCell, BaseData } from "../../../controls/table/CustomTable";
import { SortOrderModel } from "../../../../models/SortOrderModels"
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import ConfirmDialog from "../../../dialogs/confirm_dialog/ConfirmDialog";
import { ContactListModel } from "../../../../models/ContactModels";
import { CountryModel } from "../../../../models/CountryModels";
import UploadDialog from "../../../dialogs/upload_dialog/UploadDialog";
import { ApplicationUser } from "../../../../models/AuthModels";
import { appConfig } from "../../../../AppConfig";
import { TagModel } from "../../../../models/TagModels";
import dayjs, { Dayjs } from "dayjs";
import { CheckboxStates } from "../../../../models/CheckboxStates";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimeValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface ContactsListProps extends WrappedComponentProps, WithRouterProps {
	isUserLoggedIn?: boolean,
	currentUser?: ApplicationUser
}

interface ContactsListState {
	page: number;
	records: number;
	recordPerPage: number;
	data: Array<BaseData>;
	cells: Array<HeadCell>;
    fieldsOrder?: Array<SortOrderModel>;
    allCountries?: Array<CountryModel>;
	search?: string;
    email?: string;
    isSubscribed?: boolean;
    isActive?: boolean;
	isLanding?: boolean;
	createdFrom: Dayjs | null;
    createdTo: Dayjs | null;
    country?: string;
	tagId?: string;
	tags?: Array<TagModel>;
	checkedRows?: Array<string>;
	excludeRows?: Array<string>;
	headerCheckboxState: CheckboxStates;
}

class ContactsList extends React.Component<ContactsListProps, ContactsListState> {
	private roles: Array<String>;
	private hasAccess: boolean;

	constructor(props: ContactsListProps) {
		super(props);

		this.roles = this.props.currentUser?.roles ?? [];
		this.hasAccess = (this.props.isUserLoggedIn && (this.roles.includes(appConfig.roles.SUPER_ADMIN) || this.roles.includes(appConfig.roles.ADMIN))) ?? false;
		
		this.state = {
			page: 0,
			records: 0,
			recordPerPage: 10,
			headerCheckboxState: CheckboxStates.None,
			createdFrom: null,
			createdTo: null,
			data: [],
			cells: [
				{disablePadding: false, id: "email", label: "Email", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "country", label: "Country", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "tag", label: "Tag", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "issubscribed", label: "Is Subscribed?", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "isactive", label: "Is Active?", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "islanding", label: "Is Landing?", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "createdat", label: "Created At", numeric: false, orderEnabled: true},
				{disablePadding: false, id: "actions", label: "Actions", numeric: false, orderEnabled: false}],
		}

		this.getCountries();
		this.getTags();
		this.getData();
	}

	componentDidMount() {
		
	 }

	showConfirmDialog(id?: string) {
		if(this.hasAccess){
			ConfirmDialog.Show(
				"danger",
				id ? "Delete Contact" : "Delete Contacts",
				id ? "Are you shure that you want to delete this contact? The operation can not be reverted!" : "Are you shure that you want to delete these contacts? The operation can not be reverted!",
				[
					{
						text: "Delete",
						props: {
							variant: "contained",
							color: "error",
							onClick: (event) => this.handleDelete(event, id ?? null)
						}
					}
				]
			);
		}
	}

	 onFieldChangeAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		switch(name) {
			case "search": {
				this.setState({
					search: value
				});
				break;
			}
            case "email": {
				this.setState({
					email: value
				});
				break;
			}
			default:
				break;
		}
	};

	 handleRequestSort = async (event: React.MouseEvent<unknown>, property: string,
	 ) => {
		let fieldsOrder = this.state.fieldsOrder;
		let cells = this.state.cells;

		cells.map((item, index) => {
			if(item.id === property){
				item.order = (item.order ?  (item.order=== "asc" ? "desc" : undefined) : "asc");
			}
		});

		if(fieldsOrder && fieldsOrder.length > 0){
			let field = fieldsOrder.find((item) => {
				return item.name === property;
			});

			if(field){
				if(field.order === "desc"){
					fieldsOrder = fieldsOrder.filter((item) => {
						return item.name !== property && item.order !== "desc";
					});
				}
				else{
					fieldsOrder.map((item, index) => {
						if(item.name === property){
							item.order = (item.order === "asc" ? "desc" : "asc")
						}
					});
				}
			}
			else {
				fieldsOrder.push({name: property, order: "asc"});
			}
		}
		else {
			fieldsOrder = [{name: property, order: "asc"}];
		}

		await this.setState({
			fieldsOrder: fieldsOrder,
			cells: cells
		});

		this.getData();
	 };
   
	 handleChangePage = async (event: unknown, newPage: number) => {
	   await this.setState({
			page: newPage
		});

		this.getData();
	 };

	 handleFileUpload = (event: React.MouseEvent<unknown>, file?: File) => {
		if(file){
			const formData = new FormData();
    		formData.append(file.name, file);

			http.post(`/contacts/upload`, formData, {
				headers: {
				  'Content-Type': 'multipart/form-data',
				},
			  })
			.then((response) => {
				Toaster.Show("success", "Success", "File uploaded successfully!");
				this.getData();
			})
			.catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
		}
	 };

	 handleSearch = async (event: unknown) => {
		await this.setState({
			page: 0,
			checkedRows: undefined,
			excludeRows: undefined,
			headerCheckboxState: CheckboxStates.None
		});
		this.getData();
	  };

	  handleAdd = (event: unknown) => {
		this.props.navigate("/admin/contacts/create");
	  };

      handleUpload = (event: unknown) => {
		UploadDialog.Show(
			"Upload Contacts",
			this.handleFileUpload
		);
	  };

	  handleEdit = (event: unknown, id: string) => {
		this.props.navigate(`/admin/contacts/${id}`, {state: {id: id}});
	  };

	  handleDelete = (event: unknown, id: string | null) => {
		if(this.hasAccess){
			if(id){
				http.delete(`/contacts/${id}`).then((response) => {
					this.getData();
				}).catch((error) => {
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				});
			}
			else{
				const params = {
					fieldsOrder: this.state.fieldsOrder,
					search: this.state.search,
					email: this.state.email,
					tagId: this.state.tagId,
					countryId: this.state.country,
					isSubscribed: this.state.isSubscribed,
					isActive: this.state.isActive,
					isLanding: this.state.isLanding,
					ids: this.state.checkedRows,
					excludeIds: this.state.excludeRows,
					createdFrom: this.state.createdFrom !== null ? this.state.createdFrom.format('DD-MM-YYYY HH:mm') : undefined,
            		createdTo: this.state.createdTo !== null ? this.state.createdTo.format('DD-MM-YYYY HH:mm') : undefined,
				  };
				
				http.delete(`/contacts/bulkDelete`, {params})
				.then((response) => {
					this.getData();
				}).catch((error) => {
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				});
			}

			this.setState({headerCheckboxState: CheckboxStates.None, checkedRows: undefined, excludeRows: undefined});
		}
	  };

	  handleBulkDelete = (event: unknown) => {
		this.getData();
		this.showConfirmDialog();
	  };

	  handleExport = (event: unknown) => {
		this.getData();

		const params = {
			fieldsOrder: this.state.fieldsOrder,
			search: this.state.search,
            email: this.state.email,
			tagId: this.state.tagId,
            countryId: this.state.country,
            isSubscribed: this.state.isSubscribed,
			isActive: this.state.isActive,
			isLanding: this.state.isLanding,
			ids: this.state.checkedRows,
			excludeIds: this.state.excludeRows,
			createdFrom: this.state.createdFrom !== null ? this.state.createdFrom.format('DD-MM-YYYY HH:mm') : undefined,
            createdTo: this.state.createdTo !== null ? this.state.createdTo.format('DD-MM-YYYY HH:mm') : undefined,
		  };

		  http.get(`/contacts/export`, {params}).then((response) => {
			if(response?.data){
				const url = window.URL.createObjectURL(new Blob([response.data])) 
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `contacts-${dayjs().format("DD-MM-YYYY")}.csv`)
				document.body.appendChild(link)
				link.click()
				link.remove()
			}
		  }).catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	  };
   
	 handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		let recordsPerPage = parseInt(event.target.value, 10);

		await this.setState({
			recordPerPage: recordsPerPage
		});

		this.getData();
	 };

	  handleSelectCountryChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			country: value
		});
	  };

	  handleSelectTagChange = (event: SelectChangeEvent<typeof this.state.tagId>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			tagId: value
		});
	  };

      handleIsActiveSelectChange = (event: SelectChangeEvent<typeof this.state.isActive>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			isActive: typeof value === 'string' ? undefined : value
		});
	  };

	  handleIsLandingSelectChange = (event: SelectChangeEvent<typeof this.state.isLanding>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			isLanding: typeof value === 'string' ? undefined : value
		});
	  };

      handleIsSubscribedSelectChange = (event: SelectChangeEvent<typeof this.state.isSubscribed>) => {
		const {
		  target: { value },
		} = event;

		this.setState({
			isSubscribed: typeof value === 'string' ? undefined : value
		});
	  };

	handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value, id },
			} = event;

		if((this.state.headerCheckboxState == CheckboxStates.None || this.state.headerCheckboxState == CheckboxStates.Partial) && ((this.state.excludeRows?.length ?? 0) == 0)){
			if(this.state.checkedRows?.some(item => item == id)){
				this.setState({checkedRows: this.state.checkedRows.filter(function(item) { 
						return item !== id 
					}),
					headerCheckboxState: ((
						((this.state.checkedRows?.length ?? 0) - 1) >= this.state.records) ? 
						CheckboxStates.All : 
						((this.state.checkedRows?.length ?? 0) - 1) <= 0 ? 
							CheckboxStates.None : 
							CheckboxStates.Partial)
				});
			}
			else{
				var prevRows = this.state.checkedRows ?? [];
				this.setState(prev => ({
					checkedRows: [...prevRows, id],
					headerCheckboxState: (
						((this.state.checkedRows?.length ?? 0) + 1) >= this.state.records) ? 
						CheckboxStates.All : 
						((this.state.checkedRows?.length ?? 0) + 1) <= 0 ? 
							CheckboxStates.None : 
							CheckboxStates.Partial
				}))
			}
		}

		else if((this.state.headerCheckboxState == CheckboxStates.All || this.state.headerCheckboxState == CheckboxStates.Partial) && ((this.state.checkedRows?.length ?? 0) == 0)){
			if(this.state.excludeRows?.some(item => item == id)){
				this.setState({excludeRows: this.state.excludeRows.filter(function(item) { 
						return item !== id 
					}),
					headerCheckboxState: ((this.state.excludeRows.length - 1) <= 0 ? CheckboxStates.All : CheckboxStates.Partial)
				});
			}
			else{
				var prevRows = this.state.excludeRows ?? [];
				this.setState(prev => ({
					excludeRows: [...prevRows, id],
					headerCheckboxState: CheckboxStates.Partial
				}))
			}
		}
	}

	handleCheckAllRowsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch(this.state.headerCheckboxState){
			case CheckboxStates.None: {
				this.setState({headerCheckboxState: CheckboxStates.All, checkedRows: undefined, excludeRows: undefined});
				break;
			}
			case CheckboxStates.Partial: {
				this.setState({headerCheckboxState: CheckboxStates.All, checkedRows: undefined, excludeRows: undefined});
				break;
			}
			case CheckboxStates.All: {
				this.setState({headerCheckboxState: CheckboxStates.None, checkedRows: undefined, excludeRows: undefined});
				break;
			}
			default: {
				this.setState({headerCheckboxState: CheckboxStates.All, checkedRows: undefined, excludeRows: undefined});
				break;
			}
		}
	}

	onCreatedFromChangeAsync = async (value: dayjs.Dayjs | null, event: PickerChangeHandlerContext<DateTimeValidationError>) => {
		await this.setState({
			createdFrom: value
		});
	};

	onCreatedToChangeAsync = async (value: dayjs.Dayjs | null, event: PickerChangeHandlerContext<DateTimeValidationError>) => {
		await this.setState({
			createdTo: value
		});
	};

	 getCountries() {
		http.get(`/countries`)
		.then((response) => {
			let responseData = response.data as Array<CountryModel>;
			this.setState({
				allCountries: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	 }

	 getTags() {
		http.get(`/tags/getAll`)
		.then((response) => {
			let responseData = response.data as Array<TagModel>;
			this.setState({
				tags: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	 }

	 getData(){
		const params = {
			page: this.state.page <= 0 ? 1 : this.state.page+1,
			records: this.state.recordPerPage,
			fieldsOrder: this.state.fieldsOrder,
			search: this.state.search,
            email: this.state.email,
			tagId: this.state.tagId,
            countryId: this.state.country,
            isSubscribed: this.state.isSubscribed,
			isActive: this.state.isActive,
			isLanding: this.state.isLanding,
			createdFrom: this.state.createdFrom !== null ? this.state.createdFrom.format('DD-MM-YYYY HH:mm') : undefined,
            createdTo: this.state.createdTo !== null ? this.state.createdTo.format('DD-MM-YYYY HH:mm') : undefined,
		  };

		http.get(`/contacts`, 
			{params})
			.then((response) => {
				let responseData = response.data.data as Array<ContactListModel>;
				this.setState({
					page: response.data.pages < response.data.page ? 0 : response.data.page-1,
					records: response.data.records,
					data: responseData?.map((row, index) => {
						return(
							({id: row.id, cells: [
								// <TableCell key={'checkbox-'+row.id} component="th" scope="row"><Checkbox id={row.id} onChange={this.handleCheckedChange} /></TableCell>,
								<TableCell key={'email-'+row.id} component="th" scope="row">{row.email}</TableCell>,
								<TableCell key={'country-'+row.id}>{row.country}</TableCell>,
								<TableCell key={'tag-'+row.id}>{row.tag}</TableCell>,
								<TableCell key={'is-subscribed-'+row.id}>{row.isSubscribed ? "Yes" : "No"}</TableCell>,
								<TableCell key={'is-active-'+row.id}>{row.isActive ? "Yes" : "No"}</TableCell>,
								<TableCell key={'is-landing-'+row.id}>{row.isLanding ? "Yes" : "No"}</TableCell>,
								<TableCell key={'is-created-at-'+row.id}>{row.createdAt}</TableCell>,
								<TableCell key={'actions-'+row.id}>
									<Stack alignItems="center" direction="row" gap={1}>
										<Button 
											size="small" 
											variant="outlined"
											onClick={event => this.handleEdit(event, row.id)}
											>
											<Stack alignItems="center" direction="row" gap={1}>
												<EditIcon />
											</Stack>
										</Button>
										<Button 
										disabled={!this.hasAccess}
										sx={{color: red[500]}} 
										size="small" 
										variant="outlined" 
										onClick={event => this.showConfirmDialog(row.id)}>
											<Stack alignItems="center" direction="row" gap={1}>
												<DeleteIcon />
											</Stack>
										</Button>
									</Stack>
								</TableCell>
							]})
						);
					})
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	 }

	getTableFilters = () => {
		return [
			<Toolbar key="filter-group-1"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
				<FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<TextField
						fullWidth
						size='medium'
						name="email"
						label="Email"
						onChange={this.onFieldChangeAsync.bind(this)} />
				</FormControl>
                <FormControl 
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-country-label">Country</InputLabel>
					<Select
						labelId="select-country-label"
						input={<OutlinedInput id="select-country" label="Country" />}
						value={this.state.country ?? ''}
						label="Country?"
						onChange={this.handleSelectCountryChange}>
						<MenuItem><em>All</em></MenuItem>
						{this.state?.allCountries?.map((item) => {
                            return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                        })}
					</Select>
				</FormControl>
				<FormControl
					sx={{
						//pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-tag-label">Tag</InputLabel>
					<Select
						labelId="select-tag-label"
						input={<OutlinedInput id="select-tag" label="Tag" />}
						value={this.state.tagId ?? ''}
						label="Tag?"
						onChange={this.handleSelectTagChange}>
						<MenuItem><em>All</em></MenuItem>
						{this.state?.tags?.map((item) => {
                            return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                        })}
					</Select>
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-3"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }
				}}>
					<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-is-subscribed-label">Is Subscribed?</InputLabel>
					<Select
						sx={{
							width: "100%"
						}}
						labelId="select-is-subscribed-label"
						input={<OutlinedInput id="select-is-subscribed" label="Is Subscribed" />}
						value={this.state.isSubscribed ?? ''}
						label="Is Subscribed?"
						onChange={this.handleIsSubscribedSelectChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={true as any}>Yes</MenuItem>
						<MenuItem value={false as any}>No</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-is-active-label">Is Active?</InputLabel>
					<Select
						labelId="select-is-active-label"
						input={<OutlinedInput id="select-is-active" label="Is Active" />}
						value={this.state.isActive ?? ''}
						label="Is Active?"
						onChange={this.handleIsActiveSelectChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={true as any}>Yes</MenuItem>
						<MenuItem value={false as any}>No</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					sx={{
						//pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<InputLabel id="select-is-landing-label">Is Landing?</InputLabel>
					<Select
						labelId="select-is-landing-label"
						input={<OutlinedInput id="select-is-landing" label="Is Landing" />}
						value={this.state.isLanding ?? ''}
						label="Is Landing?"
						onChange={this.handleIsLandingSelectChange}>
						<MenuItem><em>All</em></MenuItem>
						<MenuItem value={true as any}>Yes</MenuItem>
						<MenuItem value={false as any}>No</MenuItem>
					</Select>
				</FormControl>
			</Toolbar>,
			<Toolbar key="filter-group-4"
				sx={{
					pl: { xs: 2 },
					pr: { xs: 2 },
					pb: { xs: 2 }}}>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateTimePicker
							label="Created From"
							value={this.state.createdFrom}
							maxDateTime={this.state.createdTo !== null ? this.state.createdTo : undefined}
							onChange={this.onCreatedFromChangeAsync.bind(this)}
							format="DD-MM-YYYY HH:mm"
							/>
					</LocalizationProvider>
				</FormControl>
				<FormControl
					sx={{
						pr: { xs: 2, sm: 2 },
						minWidth: 1/3
					}}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateTimePicker
							label="Created To"
							value={this.state.createdTo}
							minDateTime={this.state.createdFrom !== null ? this.state.createdFrom : undefined}
							onChange={this.onCreatedToChangeAsync.bind(this)}
							format="DD-MM-YYYY HH:mm"
							/>
					</LocalizationProvider>
				</FormControl>
			</Toolbar>
			];
	}

	render(): React.ReactNode {
		return(
			<>
				<CustomTable
					data={this.state.data}
					cells={this.state.cells}
					recordsPerPage={[10, 25, 50]}
					recordPerPage={this.state.recordPerPage}
					records={this.state.records}
					page={this.state.page}
					selectedRows={this.state.checkedRows}
					headerCheckboxState={this.state.headerCheckboxState}
					excludedRows={this.state.excludeRows}
					onFieldChangeAsync={this.onFieldChangeAsync}
					filters={this.getTableFilters()}
					handleChangePage={this.handleChangePage}
					handleChangeRowsPerPage={this.handleChangeRowsPerPage}
					handleCheckAllRowsChange={this.handleCheckAllRowsChange}
					handleCheckRowChange={this.handleCheckedChange}
					handleRequestSort={this.handleRequestSort}
					handleSearch={this.handleSearch}
					handleAdd={this.handleAdd}
                    handleUpload={this.handleUpload}
					handleDelete={this.handleBulkDelete}
					handleExport={this.handleExport}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isUserLoggedIn: state.auth.isUserLoggedIn,
		currentUser: state.account.currentUser
	};
};

export default withRouter(injectIntl(connect(mapStateToProps, null)(ContactsList)));
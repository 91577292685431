import React, { ChangeEvent } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { WithRouterProps, withRouter } from "../../../../router";
import { connect } from "react-redux";
import { Box, Button, Container, FormControl, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import http from "../../../../services/HttpService";
import Toaster from "../../../controls/toaster/Toaster";
import { ValidationError } from "../../../../models/ValidationErrorModel";
import { CountryModel } from "../../../../models/CountryModels";
import { EmailTemplateSimpleModel } from "../../../../models/EmailTemplateModels";
import dayjs, { Dayjs } from "dayjs";
import { CampaignModel, Status } from "../../../../models/CampaignModels";
import { DateTimePicker, DateTimeValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TagModel } from "../../../../models/TagModels";
import { EmailSenderSimpleModel } from "../../../../models/EmailSenderModels";

interface CampaignProps extends WrappedComponentProps, WithRouterProps {

}

interface CampaignState {
	id?: string;
	name: string;
	nameIsValid?: boolean;
	nameErrorMessage?: string;
    description: string;
	descriptionIsValid?: boolean;
	descriptionErrorMessage?: string;
	country?: string;
	countryIsValid?: boolean;
	countryErrorMessage?: string;
    emailTemplate?: string;
	emailTemplateIsValid?: boolean;
	emailTemplateErrorMessage?: string;
    startAt: Dayjs | null;
	startAtIsValid?: boolean;
	startAtErrorMessage?: string;
    status?: Status;
	allCountries?: Array<CountryModel>;
    allEmailTemplates: Array<EmailTemplateSimpleModel>;
	emailOpenFrom?: number;
	emailOpenFromIsValid?: boolean;
	emailOpenFromErrorMessage?: string;
	emailOpenTo?: number;
	emailOpenToIsValid?: boolean;
	emailOpenToErrorMessage?: string;
	tags: Array<TagModel>;
	tagId?: string;
	tagIdIsValid?: boolean;
	tagIdErrorMessage?: string;
	tag?: string;
	emailSenders: Array<EmailSenderSimpleModel>;
	emailSenderId?: string;
	emailSenderIdIsValid?: boolean;
	emailSenderIdErrorMessage?: string;
	emailSender?: string;
}

class Campaign extends React.Component<CampaignProps, CampaignState> {
	constructor(props: CampaignProps) {
		super(props);

		this.state = {
			name: "",
			description: "",
            startAt: null,
			tags: [],
			allEmailTemplates: [],
			emailSenders: []
		}

        this.getEmailTemplates();
		this.getCountries();
		this.getTags();
		this.getEmailSenders();

		if(this.props.params?.id){
			this.getData();
		}
	}

	async onFieldChangeAsync(event: ChangeEvent<HTMLInputElement>) {
		const { name, value } = event.target;

		if (name === "name") {
			const isValid = value.length >= 1;

			await this.setState({
				name: value,
				nameIsValid: isValid,
				nameErrorMessage: !isValid ? "Name is required" : ""
			});
		}

		if (name === "emailopenfrom") {
			const isNegativeCharacter = value.startsWith("-") && value.length === 1;

			let numberValue = parseInt(isNegativeCharacter ? value+"0" : (value === "" ? "0" : value) , 10);
			if(Number.isNaN(numberValue) && value.length >= 1)
				numberValue = 0;

			const isValid = this.state.emailOpenTo ? this.state.emailOpenTo >= numberValue : true;

			await this.setState({
				emailOpenFrom: value.length > 0 ? numberValue : undefined,
				emailOpenFromIsValid: isValid,
				emailOpenFromErrorMessage: !isValid ? "Email Open Count From should be less than or equal to Email Open Count To" : "",
				emailOpenToIsValid: isValid,
				emailOpenToErrorMessage: !isValid ? "Email Open Count To should be greather than or equal to Email Open Count From" : ""
			});
		}

		if (name === "emailopento") {
			const isNegativeCharacter = value.startsWith("-") && value.length === 1;

			let numberValue = parseInt(isNegativeCharacter ? value+"0" : (value === "" ? "0" : value) , 10);
			if(Number.isNaN(numberValue) && value.length >= 1)
				numberValue = 0;

			const isValid = this.state.emailOpenFrom ? this.state.emailOpenFrom <= numberValue : true;

			await this.setState({
				emailOpenTo: value.length > 0 ? numberValue : undefined,
				emailOpenFromIsValid: isValid,
				emailOpenFromErrorMessage: !isValid ? "Email Open Count From should be less than or equal to Email Open Count To" : "",
				emailOpenToIsValid: isValid,
				emailOpenToErrorMessage: !isValid ? "Email Open Count To should be greather than or equal to Email Open Count From" : ""
			});
		}

		if(name === "description"){
			await this.setState({
				description: value,
                descriptionIsValid: true
			});
		}
	}

    onStartAtChangeAsync = async (value: dayjs.Dayjs | null, event: PickerChangeHandlerContext<DateTimeValidationError>) => {
		await this.setState({
			startAt: value
		});
	};

	handleCountrySelectChange = (event: SelectChangeEvent<typeof this.state.country>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  country: value
		  });
	  };

	  handleTagSelectChange = (event: SelectChangeEvent<typeof this.state.tagId>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  tagId: value
		  });
	  };

	  handleEmailSenderSelectChange = (event: SelectChangeEvent<typeof this.state.emailSenderId>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  emailSenderId: value
		  });
	  };

      handleEmailTemplateSelectChange = (event: SelectChangeEvent<typeof this.state.emailTemplate>) => {
		const {
			target: { value },
		  } = event;
  
		  this.setState({
			  emailTemplate: value,
              emailTemplateIsValid: !(value === "" || value === undefined),
              emailTemplateErrorMessage: (value === "" || value === undefined ? "Email template is required" : "")
		  });
	  };

	handleCancel(event: React.MouseEvent<unknown>) {
		this.props.navigate("/admin/campaigns");
	}

	handleSaveChanges(event: React.MouseEvent<unknown>) {
		this.saveChanges();
	}

	saveChanges(){
		const params = {
			name: this.state.name,
            description: this.state.description,
            emailTemplateId: this.state.emailTemplate,
            countryId: this.state.country === "" ? undefined : this.state.country,
            startAt: this.state.startAt !== null ? this.state.startAt.format('DD-MM-YYYY HH:mm') : undefined,
			emailOpenFrom: this.state.emailOpenFrom,
			emailOpenTo: this.state.emailOpenTo,
			tagId: this.state.tagId,
			emailSenderId: this.state.emailSenderId
		  };

		if(this.state.id){
			http.put(`/campaigns/${this.state.id}`, params)
			.then((response) => {
				this.props.navigate("/admin/campaigns");
			}).catch((error) => {
				let errors = error.data?.errors as Array<ValidationError>;
				if(errors && Array.isArray(errors)){
					errors.map(item => {
						switch(item.property.toLocaleLowerCase()){
							case "name": {
                                this.setState({
                                    nameIsValid: false,
                                    nameErrorMessage: item.message
                                });
                                break;
                            }
                            case "description": {
                                this.setState({
                                    descriptionIsValid: false,
                                    descriptionErrorMessage: item.message
                                });
                                break;
                            }
                            case "emailtemplateid": {
                                this.setState({
                                    emailTemplateIsValid: false,
                                    emailTemplateErrorMessage: item.message
                                });
                                break;
                            }
							case "emailsenderid": {
                                this.setState({
                                    emailSenderIdIsValid: false,
                                    emailSenderIdErrorMessage: item.message
                                });
                                break;
                            }
                            case "countryid": {
                                this.setState({
                                    countryIsValid: false,
                                    countryErrorMessage: item.message
                                });
                                break;
                            }
                            case "startat": {
                                this.setState({
                                    startAtIsValid: false,
                                    startAtErrorMessage: item.message
                                });
                                break;
                            }
							case "emailopenfrom": {
                                this.setState({
                                    emailOpenFromIsValid: false,
                                    emailOpenFromErrorMessage: item.message
                                });
                                break;
                            }
							case "emailopento": {
                                this.setState({
                                    emailOpenToIsValid: false,
                                    emailOpenToErrorMessage: item.message
                                });
                                break;
                            }
							case "tagid": {
								this.setState({
									tagIdIsValid: false,
									tagIdErrorMessage: item.message
								});
								break;
							}
                            default: {
								Toaster.Show("error", "Error", item?.message);
								break;
							}
						}
					})
				}
				else{
					Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
				}
			});
		}
		else{
			http.post(`/campaigns`, params)
				.then((response) => {
					this.props.navigate("/admin/campaigns");
				}).catch((error) => {
					let errors = error.data?.errors as Array<ValidationError>;
					if(errors && Array.isArray(errors)){
						errors.map(item => {
							switch(item.property.toLocaleLowerCase()){
								case "name": {
									this.setState({
										nameIsValid: false,
										nameErrorMessage: item.message
									});
									break;
								}
                                case "description": {
									this.setState({
										descriptionIsValid: false,
										descriptionErrorMessage: item.message
									});
									break;
								}
                                case "emailtemplateid": {
                                    this.setState({
                                        emailTemplateIsValid: false,
                                        emailTemplateErrorMessage: item.message
                                    });
                                    break;
                                }
								case "emailsenderid": {
									this.setState({
										emailSenderIdIsValid: false,
										emailSenderIdErrorMessage: item.message
									});
									break;
								}
                                case "countryid": {
                                    this.setState({
                                        countryIsValid: false,
                                        countryErrorMessage: item.message
                                    });
                                    break;
                                }
                                case "startat": {
                                    this.setState({
                                        startAtIsValid: false,
                                        startAtErrorMessage: item.message
                                    });
                                    break;
                                }
								case "emailopenfrom": {
									this.setState({
										emailOpenFromIsValid: false,
										emailOpenFromErrorMessage: item.message
									});
									break;
								}
								case "emailopento": {
									this.setState({
										emailOpenToIsValid: false,
										emailOpenToErrorMessage: item.message
									});
									break;
								}
								case "tagid": {
									this.setState({
										tagIdIsValid: false,
										tagIdErrorMessage: item.message
									});
									break;
								}
								default: {
									Toaster.Show("error", "Error", item?.message);
									break;
								}
							}
						})
					}
					else{
						Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
					}
				});
		}
	}

	getCountries() {
		http.get(`/countries`)
		.then((response) => {
			let responseData = response.data as Array<CountryModel>;
			this.setState({
				allCountries: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getTags() {
		http.get(`/tags/getAll`)
		.then((response) => {
			let responseData = response.data as Array<TagModel>;
			this.setState({
				tags: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

	getEmailSenders() {
		http.get(`/emailSenders/getAll`)
		.then((response) => {
			let responseData = response.data as Array<EmailSenderSimpleModel>;
			this.setState({
				emailSenders: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	}

    getEmailTemplates() {
		http.get(`/emailTemplates/getAll`)
		.then((response) => {
			let responseData = response.data as Array<EmailTemplateSimpleModel>;
			this.setState({
				allEmailTemplates: responseData
			});
		})
		.catch((error) => {
			Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
		});
	 }

	getData(){
		http.get(`/campaigns/${this.props.params?.id}`)
			.then((response) => {
				let responseData = response.data as CampaignModel;

				if(!this.state.tags?.some(item => item.id === responseData.tagId)){
					this.setState(prevState => ({
						tags: [...prevState.tags, {id: responseData.tagId, name: responseData.tag, isSystem: false} as TagModel]
					}));
				}

				if(!this.state.allEmailTemplates?.some(item => item.id === responseData.emailTemplateId)){
					this.setState(prevState => ({
						allEmailTemplates: [...prevState.allEmailTemplates, {id: responseData.emailTemplateId, name: responseData.emailTemplate} as EmailTemplateSimpleModel]
					}));
				}

				if(!this.state.emailSenders?.some(item => item.id === responseData.emailSenderId)){
					this.setState(prevState => ({
						emailSenders: [...prevState.emailSenders, {id: responseData.emailSenderId, name: responseData.emailSender} as EmailSenderSimpleModel]
					}));
				}

				this.setState({
					id: responseData.id,
					name: responseData.name,
                    description: responseData.desription,
					country: responseData.countryId ?? "",
                    emailTemplate: responseData.emailTemplateId ?? "",
                    status: responseData.status,
					startAt: dayjs(responseData.startAt, "DD-MM-YYYY HH:mm"),
					emailOpenFrom: responseData.emailOpenFrom ?? undefined,
					emailOpenTo: responseData.emailOpenTo ?? undefined,
					tagId: responseData.tagId,
					tag: responseData.tag,
					emailSenderId: responseData.emailSenderId,
					emailSender: responseData.emailSender
				});
			}).catch((error) => {
				Toaster.Show("error", "Error", error?.data?.message ?? "Something went wrong");
			});
	}

	render(): React.ReactNode {
		return(
			<Paper>
				<form>
					<FormGroup 
						sx={{
							pt: { xs: 3 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										required
										fullWidth
										name="name"
										value={this.state.name}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_name" })}
										error={this.state.nameIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.nameErrorMessage} />
								</FormControl>
							</Grid>
							<Grid item xs={4}>
                                <FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<TextField
										fullWidth
										name="description"
										value={this.state.description}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_description" })}
										error={this.state.descriptionIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.descriptionErrorMessage} />
								</FormControl>
							</Grid>
                            <Grid item xs={4} >
                                <FormControl
									error={this.state.emailTemplateIsValid === false}
									sx={{
										//pr: { xs: 3, sm: 3 },
										minWidth: "100%"
									}}>
									<InputLabel required id="select-email-template-label">Email Template</InputLabel>
									<Select
										labelId="select-email-template-label"
										input={<OutlinedInput id="select-email-template" label="Email Template" />}
										value={this.state.emailTemplate ?? ''}
                                        disabled={ this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										label="Country"
										onChange={this.handleEmailTemplateSelectChange.bind(this)}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.allEmailTemplates?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
                                    <FormHelperText error={this.state.emailTemplateIsValid === false}>{this.state.emailTemplateErrorMessage}</FormHelperText>
								</FormControl>
                            </Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<InputLabel id="select-country-label">Country</InputLabel>
									<Select
										labelId="select-country-label"
										input={<OutlinedInput id="select-country" label="Country" />}
										value={this.state.country ?? ''}
                                        disabled={this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										label="Country"
                                        error={this.state.countryIsValid === false}
										onChange={this.handleCountrySelectChange.bind(this)}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.allCountries?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
                                    <FormHelperText error={this.state.countryIsValid === false}>{this.state.countryErrorMessage}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
									<FormControl
										sx={{
											width: "100%",
											pr: { xs: 2}
										}}>
										<DateTimePicker
											disabled={this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
											label="Start At"
											value={this.state.startAt}
											minDateTime={this.props.params?.id ? (this.state.startAt ?? dayjs()) : dayjs()}
											onChange={this.onStartAtChangeAsync.bind(this)}
											format="DD-MM-YYYY HH:mm"
											/>
									</FormControl>
                                </LocalizationProvider>
							</Grid>
							<Grid item xs={4} sx={{display: "flex"}}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2},
										//p: "auto",
										display: "inline"
									}}>
									<TextField
										fullWidth
										name="emailopenfrom"
										disabled={ this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										value={this.state.emailOpenFrom ?? ""}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_email_open_from" })}
										error={this.state.emailOpenFromIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.emailOpenFromErrorMessage} />
								</FormControl>
								<FormControl 
									sx={{
										width: "100%",
										// p: "auto",
										//pr: { xs: 2},
										alignItems: "right",
										display: "inline",
									}}>
									<TextField
										fullWidth
										name="emailopento"
										disabled={ this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										value={this.state.emailOpenTo ?? ""}
										inputProps={{
											form: {
											  autoComplete: 'off',
											},
										  }}
										label={this.props.intl.formatMessage({ id: "labels_email_open_to" })}
										error={this.state.emailOpenToIsValid === false}
										onChange={this.onFieldChangeAsync.bind(this)}
										onBlur={(event) => this.onFieldChangeAsync(event as ChangeEvent<HTMLInputElement>)} 
										helperText={this.state.emailOpenToErrorMessage} />
								</FormControl>
							</Grid>
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							//pb: { xs: 3 },
							pr: { xs: 2 },
							pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item xs={4}>
								<FormControl
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<InputLabel id="select-tag-label">Tag</InputLabel>
									<Select
										labelId="select-tag-label"
										input={<OutlinedInput id="select-tag" label="Tag" />}
										value={this.state.tagId ?? ''}
                                        disabled={this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										label="Tag"
                                        error={this.state.tagIdIsValid === false}
										onChange={this.handleTagSelectChange.bind(this)}>
										<MenuItem><em>All</em></MenuItem>
										{this.state?.tags?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
                                    <FormHelperText error={this.state.tagIdIsValid === false}>{this.state.tagIdErrorMessage}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl
									error={this.state.emailTemplateIsValid === false}
									sx={{
										width: "100%",
										pr: { xs: 2}
									}}>
									<InputLabel required id="select-email-sender-label">Email Sender</InputLabel>
									<Select
										labelId="select-email-sender-label"
										input={<OutlinedInput id="select-email-sender" label="Email Sender" />}
										value={this.state.emailSenderId ?? ''}
                                        disabled={this.state.status !== undefined && this.state.status !== Status.None && this.state.status !== Status.Pending}
										label="Email Sender"
                                        onChange={this.handleEmailSenderSelectChange.bind(this)}>
										<MenuItem><em>None</em></MenuItem>
										{this.state?.emailSenders?.map((item) => {
											return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
										})}
									</Select>
                                    <FormHelperText error={this.state.emailSenderIdIsValid === false}>{this.state.emailSenderIdErrorMessage}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={4} />
						</Grid>
					</FormGroup>
					<FormGroup 
						sx={{
							pt: { xs: 2 },
							pb: { xs: 3 },
							//pr: { xs: 2 },
							//pl: { xs: 2 }
						}}>
						<Grid container>
							<Grid item lg={9} md={8} sm={7} xs={6} />
							<Grid item lg={3} md={4} sm={5} xs={6} >
								<Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
									<Button
										sx={{
											px: 2,
											mr: 1,
											ml: "auto"
										}}
										onClick={this.handleCancel.bind(this)}
										variant="outlined">
										Cancel
									</Button>
									<Button
										sx={{
											//px: 2,
										}}
										onClick={this.handleSaveChanges.bind(this)}
										variant="contained">
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</Paper>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	};
};

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Campaign)));